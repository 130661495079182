import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Bugsnag from "@bugsnag/js";
import Link from "next/link";
import { Spin, Breadcrumb, Select, Button, Modal } from "antd";
import styles from "@aspen/theme/Wallet.module.less";
import { IkycInfo, ICircleBank } from "@aspen/model";
import { getBankList, addCircleAccount } from "@aspen/services";
import {
  formatBugsnagMessage,
  countryCode2Info,
  reportEvent,
  i18nUtil,
  GA_EVENT_NAME,
  REFILL_BANK_INFO,
  WALLET_PATHS,
  isClient
} from "@aspen/libs";
import { message, WithTrimInput, WithKycInfo, UploadFile } from "@aspen/ui";

const { Option } = Select;

interface IProps {
  getKYCInfo: () => void;
  kycInfo: IkycInfo;
}

function DepositUSDC(props: IProps) {
  const intl = i18nUtil.t();
  const title_text: string = intl["wallet.circle.add.title"];
  const btnOk_text: string = intl["button.ok"];
  const btnSubmit_text: string = intl["button.submit"];
  const notice_text: string = intl["wallet.circle.notice"];
  const location_text: string = intl["kyc.certify.location"];
  const wallet_text: string = intl["wallet"];
  const bankInformation_text: string = intl["wallet.circle.bank.information"];
  const bankName_text: string = intl["wallet.circle.bank.name"];
  const swiftCode_text: string = intl["wallet.circle.swift"];
  const country_text: string = intl["wallet.circle.country"];
  const city_text: string = intl["wallet.circle.city"];
  const bankAddress_text: string = intl["wallet.circle.bank.address"];
  const bankAccount_text: string = intl["wallet.circle.bank.account"];
  const accountInformation_text: string = intl["wallet.circle.account.information"];
  const fullName_text: string = intl["wallet.circle.name"];
  const postalCode_text: string = intl["wallet.circle.postal"];
  const address_text: string = intl["wallet.circle.address"];
  const bankStatements_text: string = intl["wallet.circle.bank.statements"];
  const uploadSgin_text: string = intl["wallet.circle.upload.sgin"];
  const uploadTips_text = intl["wallet.circle.upload.tips"];
  const noticeContent_text = intl["wallet.circle.add.notice.content"];
  const modalTitle_text: string = intl["wallet.circle.add.modal.title"];
  const modalContent_text: string = intl["wallet.circle.add.modal.content"];

  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [bankList, setBankList] = useState<any>([]);
  const [currentBank, setCurrentBank] = useState<ICircleBank>();
  const [bankAccount, setBankAccount] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userRegion, setUserRegion] = useState<string>(""); //目前接口没有字段
  const [userAddress, setUserAddress] = useState<string>("");
  const [uploadInfo, setUploadInfo] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const router = useRouter();

  // 11.09 需求要求写死此字段
  const userCity: string = "HONG KONG";
  const userPostalCode: string = "999077";

  // 回显银行信息
  const refillInfo =
    isClient &&
    localStorage.getItem(REFILL_BANK_INFO) &&
    JSON.parse(localStorage.getItem(REFILL_BANK_INFO) ?? "");

  useEffect(() => {
    getInitData();
  }, []);

  useEffect(() => {
    setUserName(props.kycInfo?.name ?? "");
    setUserRegion(countryCode2Info()[props.kycInfo.countryCode ?? ""]?.commonName);
  }, [props.kycInfo]);

  // 初始化数据,
  const getInitData: () => void = async () => {
    setLoading(true);
    props.getKYCInfo();
    await getBankList()
      .then((res) => {
        if (res?.code == "0") {
          setBankList(res?.data);
          if (refillInfo) {
            const index = res?.data.findIndex((e) => e.name == refillInfo.bankName);
            setCurrentBank(index !== -1 ? res?.data[index] : null); // 回显银行信息
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
    if (refillInfo) updateRefillInfo();
  };

  // 设置回显银行信息
  const updateRefillInfo = () => {
    setBankAccount(refillInfo.accountNumber); // 回显银行账户
    setUploadInfo(refillInfo.proof);
    setUserAddress(refillInfo.customerStreet);
  };

  const handleChangeBank = (e: number) => {
    setCurrentBank(bankList[e]);
  };
  const handleChangeBnakAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBankAccount(e.target.value);
  };

  const handleChangeUserAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserAddress(e.target.value);
  };

  //获取上传凭证url
  const getTransfer = (value: string) => {
    let info: string = "";
    if (value) {
      info = value;
    }
    setUploadInfo(info);
    // 保存上传信息
    handleSaveUserInfo(null, info);
  };

  // 判断
  useEffect(() => {
    checkSubmitInfo();
  }, [currentBank, bankAccount, userAddress, uploadInfo]);

  // 按钮是否可提交
  const checkSubmitInfo = () => {
    const test =
      currentBank?.name &&
      currentBank?.swiftCode &&
      currentBank?.country &&
      currentBank?.city &&
      currentBank?.address &&
      bankAccount &&
      userCity &&
      userPostalCode &&
      userAddress &&
      uploadInfo &&
      true;
    if (test) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleClickSubmit = () => {
    setLoading(true);
    const params = {
      type: 1,
      usage: 1,
      bankName: currentBank?.name ?? "",
      swiftCode: currentBank?.swiftCode ?? "",
      country: currentBank?.country ?? "",
      city: currentBank?.city ?? "",
      street: currentBank?.address ?? "",
      accountNo: bankAccount,
      userName: userName,
      countryCode: "HK",
      customerCity: userCity,
      customerPostal: userPostalCode,
      customerStreet: userAddress,
      proof: uploadInfo
    };
    addCircleAccount(params)
      .then((res) => {
        if (res?.code == "0") {
          message.success(intl["system.successful"]);
          router.replace(WALLET_PATHS.WALLET);
        } else {
          setLoading(false);
          Bugsnag.notify(
            new Error(formatBugsnagMessage(JSON.stringify(res, null, "\t"), "Action-Add-Bank"))
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
    reportEvent({ moduleName: GA_EVENT_NAME.wallet.addCircleAccount });
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  // 当数据失去焦点时，保存用户填写的信息
  const handleSaveUserInfo = (e, upload?: string) => {
    const refillInfo = {
      accountNumber: bankAccount,
      proof: upload !== undefined ? upload : uploadInfo,
      customerCity: userCity,
      customerPostal: userPostalCode,
      customerStreet: userAddress,
      bankName: currentBank?.name
    };
    localStorage.setItem(REFILL_BANK_INFO, JSON.stringify(refillInfo));
  };

  return (
    <section className={styles.depositCircle}>
      <Spin spinning={loading}>
        <Breadcrumb>
          <Breadcrumb.Item>{location_text}:</Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={WALLET_PATHS.WALLET}>{wallet_text}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{title_text}</Breadcrumb.Item>
        </Breadcrumb>
        <div className={styles.circleContent}>
          <p className={styles.title}>{title_text}</p>
          <div className={styles.addContent}>
            <p className={styles.title}>{bankInformation_text}</p>
            <div className={styles.infoCotnet}>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{bankName_text}</p>
                  {bankList?.length > 0 && (
                    <Select
                      style={{ width: "100%" }}
                      onChange={handleChangeBank}
                      onBlur={handleSaveUserInfo}
                      defaultValue={
                        refillInfo?.bankName
                          ? bankList?.findIndex((bank) => bank.name === refillInfo.bankName)
                          : null
                      }
                      placeholder={intl["placeholder.select"]}
                      size="large">
                      {bankList.map((item, index) => (
                        <Option key={index} value={index}>
                          {`${item.name} ${item.bankCode ? `(${item.bankCode})` : ""}`}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{swiftCode_text}</p>
                  <p className={styles.itemArea}>{currentBank?.swiftCode}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{country_text}</p>
                  <p className={styles.itemArea}>{currentBank?.country}</p>
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{city_text}</p>
                  <p className={styles.itemArea}>{currentBank?.city}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent} style={{ width: "100%" }}>
                  <p className={styles.itemTitle}>{bankAddress_text}</p>
                  <p className={styles.itemArea}>{currentBank?.address}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{bankAccount_text}</p>
                  <WithTrimInput
                    placeholder={intl["placeholder.enter"]}
                    value={bankAccount}
                    maxLength={100}
                    onChange={handleChangeBnakAccount}
                    onBlur={handleSaveUserInfo}
                  />
                </div>
              </div>
            </div>
            <p className={styles.title}>{accountInformation_text}</p>
            <div className={styles.infoCotnet}>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{fullName_text}</p>
                  <p className={styles.itemArea}>{userName}</p>
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{country_text}</p>
                  <p className={styles.itemArea}>{userRegion}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{city_text}</p>
                  <p className={styles.itemArea}>{userCity}</p>
                </div>
                <div className={styles.itemContent}>
                  <p className={styles.itemTitle}>{postalCode_text}</p>
                  <p className={styles.itemArea}>{userPostalCode}</p>
                </div>
              </div>
              <div className={styles.itemsContent}>
                <div className={styles.itemContent} style={{ width: "100%" }}>
                  <p className={styles.itemTitle}>{address_text}</p>
                  <WithTrimInput
                    style={{ marginBottom: 10 }}
                    maxLength={140}
                    value={userAddress}
                    placeholder={intl["placeholder.billing.address"]}
                    onChange={handleChangeUserAddress}
                    onBlur={handleSaveUserInfo}
                  />
                </div>
              </div>
            </div>
            <p className={styles.title}>{bankStatements_text}</p>
            <div className={styles.uploadContent}>
              <div className={styles.uploadContainer}>
                {refillInfo?.proof ? (
                  <UploadFile
                    echo={refillInfo.proof}
                    type="bankstatements"
                    text={uploadSgin_text}
                    getUploadInfo={getTransfer}
                  />
                ) : (
                  <UploadFile
                    type="bankstatements"
                    text={uploadSgin_text}
                    getUploadInfo={getTransfer}
                  />
                )}
              </div>
              <div className={styles.rightArea}>
                <p style={{ marginBottom: 15, color: "#fff" }}>{uploadSgin_text}</p>
                {uploadTips_text.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
            </div>
            <p className={styles.title}>{notice_text}</p>
            {noticeContent_text.map((item, index) => {
              return (
                <p style={{ color: "rgba(255,255,255,0.7)" }} key={index}>
                  {item}
                </p>
              );
            })}
            <Button
              type="primary"
              size="large"
              disabled={disabled}
              onClick={handleClickSubmit}
              style={{ width: 128, marginTop: 40 }}>
              {btnSubmit_text}
            </Button>
          </div>
        </div>
        <Modal
          open={showModal}
          title={modalTitle_text}
          closable={false}
          width={480}
          wrapClassName={styles.modal}
          onCancel={handleShowModal}
          footer={[
            <Button key="ok" type="primary" onClick={handleShowModal}>
              {btnOk_text}
            </Button>
          ]}>
          <p className={styles.content}>{modalContent_text}</p>
        </Modal>
      </Spin>
    </section>
  );
}

export const PageAddAccount = WithKycInfo(DepositUSDC);
