import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Divider, Spin } from "antd";
import { DownOutlined, StarFilled } from "@ant-design/icons";
import Bugsnag from "@bugsnag/js";
import { useRouter } from "next/router";
import { connect } from "react-redux";
import styles from "@aspen/theme/Trade.module.less";
import {
  IkycInfo,
  IPrecisions,
  IReqFavoritesSymbols,
  IReqSpotBalance,
  IResOrders,
  IResOrdersHistory,
  ITrade
} from "@aspen/model";
import {
  convertUSD2USDC,
  convertUSDC2USD,
  formatBugsnagMessage,
  i18nUtil,
  isPad,
  ONLY_MOBILE,
  reportEvent,
  COIN_CODE,
  GA_EVENT_NAME,
  GA_EVENT_TAG,
  TRADE_PATH,
  USER_AUTH,
  USER_ROLE_POWER
} from "@aspen/libs";
import {
  activeSymbolPrecisions,
  favoritesSymbolsName,
  openOrder,
  updateFavoritesSymbolsName,
  updateHoldingAssetsList,
  useAppDispatch,
  useAppSelector
} from "@aspen/store";
import {
  addFavoritesSymbols,
  availableSpots,
  cancelTradeOrder,
  fetchAccountAssetsList,
  getFavoritesSymbolsList,
  ordersOpen,
  removeFavoritesSymbols
} from "@aspen/services";
import { Kline, PlaceOrder, OrderList, SymbolInfo, SymbolDrawer } from "@aspen/widgets";
import { message, WithKycInfo, WithFiatAndSpotTrade, Disclaimer } from "@aspen/ui";

const minKlineHeight = "380px";

interface IProps extends ITrade {
  kycInfo: IkycInfo;
  getKYCInfo: () => void;
}

interface IInterval {
  interval: number | string;
  timeFrame: string;
  text: number | string;
}

function Trade(props: IProps) {
  const intervalTypes: IInterval[] = [
    {
      interval: 15,
      timeFrame: "P15m",
      text: "15m"
    },
    {
      interval: 1 * 60,
      timeFrame: "P1h", // 产品要求将60min 显示成1H
      text: "1H"
    },
    {
      interval: 4 * 60,
      timeFrame: "P4h",
      text: "4H"
    },
    {
      interval: 1 * 24 * 60,
      timeFrame: "P1d",
      text: "1D" //P1M,一个月
    },
    {
      interval: 7 * 24 * 60,
      timeFrame: "P1w",
      text: "1W"
    }
  ];
  const openOrderLimit: number = 100;
  const router = useRouter();
  let _coinCode = convertUSDC2USD(router?.query?.coinCode?.toString() ?? "BTC_USDT");
  const dispatch = useAppDispatch();

  // 所有盘口精度
  const allActiveSymbolPrecisions = useAppSelector(activeSymbolPrecisions);
  // 自选交易对列表
  const favoritesSymbols = useAppSelector(favoritesSymbolsName);

  // open order
  const currentOpenOrder = useAppSelector(openOrder);
  const [coinCode, setCoinCode] = useState<string>(_coinCode);
  const [coinIsStar, setCoinIsStar] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const currentSymbolPrecision: IPrecisions = allActiveSymbolPrecisions?.[coinCode];
  const [fromSymbol, setFromSymbol] = useState<string>("");
  const [switcher, setSwitcher] = useState<string>("kline");
  const [openOrderList, setCurrentOrderList] = useState<IResOrders[]>([]);
  const [tradeHistory, setOrderHistory] = useState<IResOrders[]>([]);
  const [interval, updateInterval] = useState<string | number>("15");
  const [timeFrame, updateTimeFrame] = useState<string>("P15m");
  const [quoteCurrencyBalance, updateQuoteCurrencyBalance] = useState(0);
  const [baseCurrencyBalance, setBaseCurrencyBalance] = useState(0);
  const [quoteCurrency, setQuoteCurrency] = useState<string>(""); // 定价币种
  const [baseCurrency, setBaseCurrency] = useState<string>(""); // 计价币种
  const [loadingMarket, setLoadingMarket] = useState<boolean>(true);
  const [loadingPlaceOrder, setLoadingPlaceOrder] = useState<boolean>(true);
  const [loadinList, setLoadingList] = useState<boolean>(true);
  const [klineType, switchKlineType] = useState("TradingView");
  const intl = i18nUtil.t();

  const updateIntervalInfo: (item: IInterval) => void = (item) => {
    setLoadingMarket(true);
    updateInterval(item["interval"]);
    updateTimeFrame(item["timeFrame"]);
    setTimeout(() => {
      setLoadingMarket(false);
      setLoadingPlaceOrder(false);
    }, 0 * 1000);

    reportEvent({
      moduleName: GA_EVENT_NAME.trade.switchTimeTab,
      detailParams: { timeTab: item["interval"], Time: item["text"], symbol: coinCode }
    });
  };

  // 初始化数据
  const getInitData: () => void = async () => {
    setLoadingMarket(true);
    setLoadingList(true);
    setLoadingPlaceOrder(true);
    getOrdersOpen();
    // 请求用户kyc数据
    getUserKyc();
    // 获取自选交易对列表
    getFavoritesList();
    getHoldingAsstesList();
  };

  useLayoutEffect(() => {
    const _coinCode = convertUSDC2USD(router?.query?.coinCode?.toString() ?? "BTC_USDT");
    setCoinCode(_coinCode);
  }, [router?.query?.coinCode]);

  useEffect(() => {
    getInitData();
  }, []);

  // open order 发生变化
  useEffect(() => {
    const changedEntrustOrder = currentOpenOrder;
    if (!changedEntrustOrder) return;
    const entrustList = [...openOrderList];
    let changeEntrustIndex = -1;
    for (let item of entrustList) {
      if (item.orderId == changedEntrustOrder.orderId) {
        changeEntrustIndex = entrustList.indexOf(item);
        break;
      }
    }
    if (changeEntrustIndex != -1) {
      // 有成交时重新获取当前盘口的账户余额
      getFlexibleBalance([baseCurrency, quoteCurrency]);
      entrustList[changeEntrustIndex] = changedEntrustOrder;
      setCurrentOrderList(entrustList);
      if (entrustList[changeEntrustIndex].remainingQty == 0) {
        getOrdersOpen();
        getTradeHistory;
        return;
      }
    }
  }, [currentOpenOrder]);

  // 切换盘口时，重新获取新的币种信息、余额、切换wsroom/ orderbook等
  useEffect(() => {
    setLoadingMarket(true);
    localStorage.setItem(COIN_CODE, coinCode);
    props.sendOrderBookWsData && props.sendOrderBookWsData(coinCode, fromSymbol);
    // 从接口获取当前盘口orderbook, 不展示ob了， 故不用发送请求
    const availableList = coinCode.split("_");
    const baseCurrency = availableList[0];
    const quoteCurrency = availableList[1];
    setBaseCurrency(baseCurrency);
    setQuoteCurrency(quoteCurrency);
    // 获取当前交易对的交易币种/计价币种余额
    getFlexibleBalance([baseCurrency, quoteCurrency]);
    setTimeout(() => {
      setLoadingMarket(false);
      setLoadingPlaceOrder(false);
    }, 2 * 1000);
  }, [coinCode]);

  useEffect(() => {
    const callback = (msg) => {
      // 验证消息来源地址 next版本为vercel部署地址 所以orign为特定地址 "tradingview-nextjs-javascript"
      if (
        !(
          msg.origin.includes("localhost") ||
          msg.origin.includes("aspendigital.co") ||
          msg.origin.includes("tradingview-portal")
        )
      ) {
        return;
      }

      if (
        Object.prototype.toString.call(msg?.data) == "[object String]" &&
        msg?.data != "completed"
      ) {
        Bugsnag.notify(new Error(`${msg.origin}-${formatBugsnagMessage(msg?.data)}`));
      }

      if (msg?.data == "completed") {
        setLoadingMarket(false);
      }
    };

    window.addEventListener("message", callback);

    return () => {
      window.removeEventListener("message", callback);
    };
  }, []);

  const getFavoritesList = () => {
    getFavoritesSymbolsList().then((res) => {
      if (res?.code == "0") {
        const symbols = res?.data?.symbols ?? [];
        let list: string[] = [];
        symbols?.map((item) => {
          list.push(item.symbol);
        });
        if (list?.some((e) => e == coinCode)) {
          setCoinIsStar(true);
        } else {
          setCoinIsStar(false);
        }
        dispatch(updateFavoritesSymbolsName({ favoritesSymbolsName: list }));
      }
    });
  };
  // 获取用户持有资产交易对
  const getHoldingAsstesList = () => {
    fetchAccountAssetsList().then((res) => {
      if (res?.code == "0") {
        const symbols = res?.data ?? [];
        dispatch(updateHoldingAssetsList({ holdingAssetsList: symbols }));
      }
    });
  };

  const getUserKyc = () => {
    const user_auth = (typeof window != "undefined" && localStorage.getItem(USER_AUTH)) || "";
    if (USER_ROLE_POWER[user_auth]?.actionForOthers) {
      props.getKYCInfo();
    }
  };

  // 下单
  const handlerAfterCreateSpotTradeOrder: () => void = async () => {
    // 下单成功后重新获取open order 和余额
    getOrdersOpen();
    getFlexibleBalance([baseCurrency, quoteCurrency]);
    // 下单成功后 更新持有资产交易对
    getHoldingAsstesList();
  };

  // 交易对切换
  const handleChange: (value: string) => void = (value) => {
    setShowDrawer(false);
    if (value === coinCode) return;
    const _symbol = `${TRADE_PATH}/${value}`;
    router.push({ pathname: `${convertUSD2USDC(_symbol)}` });
    setFromSymbol(coinCode);
    setLoadingMarket(true);
    setCoinCode(value);
    favoritesSymbols?.some((e) => e == value) ? setCoinIsStar(true) : setCoinIsStar(false);
    reportEvent({
      moduleName: GA_EVENT_NAME.trade.switchSymbolPair,
      detailParams: { symbol: convertUSD2USDC(_symbol) }
    });
  };

  // 切换k线/depth
  const handleChangeSwiter: (type: string) => void = (type: string) => {
    setSwitcher(type);
    reportEvent({
      moduleName: GA_EVENT_NAME.trade[type] ?? type,
      detailParams: {
        symbol: coinCode
      }
    });
  };

  // 取消订单
  const handleCancelOrder = (orderId: string) => {
    const param = {
      orderId
    };
    cancelTradeOrder(param)
      .then((res) => {
        if (res?.code == "0") {
          setTimeout(() => {
            // 取消后立即成功的要重新获取账户余额
            getFlexibleBalance([baseCurrency, quoteCurrency]);
            getOrdersOpen();
          }, 2 * 1000);
          message.success(intl["trade.entrust.cancel.success"]);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch(() => {});
  };

  // 获取现货币种余额
  const getFlexibleBalance: (currency: string[]) => void = async (currency) => {
    const param: IReqSpotBalance = {
      currency: currency.toString(),
      category: 0
    };
    await availableSpots(param)
      .then((res) => {
        if (res?.code == "0") {
          const data = res?.data;
          const availableList = coinCode.split("_");
          const baseCurrency = availableList[0];
          const quoteCurrency = availableList[1];
          setBaseCurrencyBalance(data?.[baseCurrency] ?? 0);
          updateQuoteCurrencyBalance(data?.[quoteCurrency] ?? 0);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const getOrdersOpen = (isRefresh?: boolean) => {
    setLoadingList(true);
    const param = { limit: openOrderLimit, orderQueryStatus: "open" };
    ordersOpen(param)
      .then((res) => {
        if (res?.code == "0") {
          const orderOpenList = res?.data || [];
          setCurrentOrderList(orderOpenList);
          // judgeFilledMarkerOrder(orderOpenList, "open");
        }
      })
      .finally(() => {
        setLoadingList(false);
      });
    // 如果是刷新openorder，再去获取余额，应对cancel processing成功后的余额解冻
    if (isRefresh) {
      getFlexibleBalance([baseCurrency, quoteCurrency]);
    }
  };

  const getTradeHistory = () => {
    setLoadingList(true);
    const param = { limit: openOrderLimit, orderQueryStatus: "history" };
    ordersOpen(param)
      .then((res: IResOrdersHistory) => {
        if (res?.code == "0") {
          const orderHistoryList = res?.data || [];
          setOrderHistory(orderHistoryList);
          // judgeFilledMarkerOrder(orderHistoryList, "history");
        }
      })
      .finally(() => {
        setLoadingList(false);
        window.scrollTo(0, 500)
      });
  };

  const handleClickFavorites = () => {
    const params: IReqFavoritesSymbols = {
      favorite_type: "symbol",
      items: coinCode
    };
    const method = coinIsStar ? removeFavoritesSymbols : addFavoritesSymbols;
    reportEvent({
      moduleName: GA_EVENT_NAME.trade.favorites.favSingleChoice,
      detailParams: { type: coinIsStar ? "remove" : "add" }
    });
    method(params)
      .then((res) => {
        if (res?.code == "0") {
          let list = [...favoritesSymbols];
          if (coinIsStar) {
            dispatch(
              updateFavoritesSymbolsName({
                favoritesSymbolsName: list?.filter((e) => e != coinCode)
              })
            );
          } else {
            list.push(coinCode);
            dispatch(updateFavoritesSymbolsName({ favoritesSymbolsName: list }));
          }
          message.success(
            coinIsStar
              ? intl["trade.favorites.symbols.remove.tips"]
              : intl["trade.favorites.symbols.add.tips"]
          );
          setCoinIsStar(!coinIsStar);
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .catch((err) => {
        message.error(intl?.[err?.msg] ?? err?.msg);
      });
  };

  return (
    <section className={styles.exchange}>
      <div className={styles.grid}>
        <div className={styles.girdKline}>
          <div className={styles.trade}>
            <div className={styles.switcher}>
              <Button>{intl["trade.chart.time"]}</Button>
              {intervalTypes.map((item: IInterval) => (
                <Button
                  className={interval == item.interval ? styles.activeInterval : " "}
                  onClick={() => updateIntervalInfo(item)}
                  key={item.text}>
                  {item["text"]}
                </Button>
              ))}
              <div style={{ float: "right", display: "flex" }}>
                {/* 小屏幕时下面太窄 放不下了 */}
                {(ONLY_MOBILE || isPad()) && (
                  <div className={styles.switcher} style={{ marginRight: 16 }}>
                    <span
                      className={switcher == "kline" ? styles.current : ""}
                      onClick={() => handleChangeSwiter("kline")}>
                      {intl["trade.chart.kline"]}
                    </span>
                  </div>
                )}
                <Button
                  className={klineType == "TradingView" ? styles.activeInterval : " "}
                  onClick={() => {
                    switchKlineType("TradingView");
                    reportEvent({
                      moduleName: GA_EVENT_NAME.trade.klineTradingView,
                      detailParams: { symbol: coinCode }
                    });
                  }}>
                  {intl["trade.chart.tradingview"]}
                </Button>
                <Button
                  className={klineType == "Original" ? styles.activeInterval : " "}
                  onClick={() => {
                    switchKlineType("Original");
                    reportEvent({
                      moduleName: GA_EVENT_NAME.trade.klineOrigin,
                      detailParams: {
                        symbol: coinCode
                      }
                    });
                  }}>
                  {intl["trade.chart.origin"]}
                </Button>
              </div>
            </div>

            <div className={styles.tradeHeader}>
              <div className={styles.coinCodeContent}>
                <StarFilled
                  className={coinIsStar ? styles.coinCodeStar : styles.coinCodeNoStar}
                  onClick={handleClickFavorites}
                />
                <div
                  className={styles.coinCodeContent}
                  onClick={() => {
                    reportEvent({
                      moduleName: GA_EVENT_NAME.trade.favorites.tradingPair,
                      joinedTag: GA_EVENT_TAG.Modal
                    });
                    setShowDrawer(true);
                  }}>
                  <span className={styles.coinCodeText}>
                    {convertUSD2USDC(coinCode).replace("_", "/")}
                  </span>
                  <DownOutlined className={styles.coinCodeIcon} />
                </div>
              </div>

              <SymbolInfo
                // @ts-ignore
                loading={loadingPlaceOrder}
                coinCode={coinCode}
                currentSymbolPrecision={currentSymbolPrecision}
              />
              {(ONLY_MOBILE || isPad()) && (
                <div className={styles.switcher}>
                  <span
                    className={switcher == "kline" ? styles.current : ""}
                    onClick={() => handleChangeSwiter("kline")}>
                    {intl["trade.chart.kline"]}
                  </span>
                </div>
              )}
            </div>

            <div
              className={
                switcher == "kline" ? styles.klineChartContainer : styles.depthChartContainer
              }>
              {switcher == "kline" && (
                <>
                  <Spin spinning={loadingMarket}>
                    <Kline
                      klineType={klineType}
                      minHeight={minKlineHeight}
                      coinCode={router?.query?.coinCode ? coinCode : ""}
                      timeFrame={timeFrame}
                      interval={interval}
                      tradeVolumePrecision={currentSymbolPrecision?.tradeVolumePrecision}
                      precision={currentSymbolPrecision?.symbolPrecision ?? 2}
                    />
                  </Spin>
                </>
              )}
            </div>
          </div>

          <PlaceOrder
            handleChangeSymbol={handleChange}
            loadingPlaceOrder={loadingPlaceOrder}
            baseCurrencyBalance={baseCurrencyBalance}
            quoteCurrencyBalance={quoteCurrencyBalance}
            baseCurrency={baseCurrency}
            quoteCurrency={quoteCurrency}
            currentSymbolPrecision={currentSymbolPrecision}
            coinCode={coinCode}
            handlerAfterCreateSpotTradeOrder={handlerAfterCreateSpotTradeOrder}
          />
        </div>
        <div className={styles.orderList}>
          <OrderList
            activeSymbolPrecisions={allActiveSymbolPrecisions}
            offlineSymbolsList={props.offlineSymbolsList}
            loading={loadinList}
            getOrdersOpen={getOrdersOpen}
            getTradeHistory={getTradeHistory}
            openOrderList={openOrderList}
            tradeHistory={tradeHistory}
            handleCancelOrder={handleCancelOrder}
          />
        </div>
      </div>
      <Divider />
      <Disclaimer />
      {showDrawer ? (
        <SymbolDrawer
          visible={showDrawer}
          onClose={() => {
            setShowDrawer(false);
          }}
          handleChangeSymbols={handleChange}
          getFavoritesList={getFavoritesList}
        />
      ) : null}
    </section>
  );
}

const mapStateToProps = (state) => {
  const { openOrder } = state.marketSpotAndTradeInfo;
  return {
    openOrder
  };
};

export const PageTrade = connect(mapStateToProps)(
  WithFiatAndSpotTrade(React.memo(WithKycInfo(Trade)), {
    needSymbols: true,
    needWebsocket: true
  })
);
